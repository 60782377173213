import { axiosGet, axiosDelete, axiosPut, axiosPost, axiosUpload, axiosPostToUrl } from './config'
import router from '../router'
import store from '../store'

// const url = 'http://admdev.bujaschool.com/adm_api/lecture/category'
/**
 * 카테고리 가져오기
 */
const url = 'lecture/category'
export async function getCategory() {
  //ca_na => id
  //null => []
  try {
    var response = await axiosGet(url)
    if (response.code === '0000') {
      response = JSON.stringify(response)
      response = response
      // .replace(/ca_sn/gi, 'id')
      // .replace(/title/gi, 'name')
      // .replace(/null/gi, '[]')
      response = JSON.parse(response)
      // var temp = response.data.sort((a, b) => {
      //   if (a.menu_order == 0 || b.menu_order == 0) return1
      //   else return a.menu_order - b.menu_order
      // })
      // response = temp
      return response
    } else {
      // alert(`error: ${JSON.stringify(_error)}`)
      store.dispatch('user/clearUser')
      router.push('/login')
    }
  } catch (error) {
    alert('lecture error....')
    // store.dispatch('user/clearUser')
    // router.push('/login')
    // window.location.href = '/login'
  }
}

/**
 * 메뉴 카테고리 생성
 * @param {*} title 타이틀
 * @param {*} menu_depth 뎁스(1,2,3)
 * @param {*} p_ca_sn 부모 인뎃스
 * @param {*} description 추가할 카테고리의 설명
 * @param {*} menu_order 메뉴 순서
 * @param {*} is_hidden 하위메뉴 노출여부(Y/N)
 * @returns
 */
export async function createCategory(
  title,
  menu_depth,
  p_ca_sn,
  description,
  menu_order,
  is_hidden = 'N',
  is_close = 'N',
  lc_no = '00'
) {
  try {
    var data = {
      title: title,
      menu_depth: menu_depth,
      p_ca_sn: p_ca_sn,
      contents: description,
      menu_order: menu_order,
      is_hidden: is_hidden,
      is_close,
      lc_no
    }
    let response = await axiosPost(url, data)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
  return null
}

/**
 * 카테고리 삭제
 * @param {*} ca_sn  강좌목록ID
 */
export async function deleteCategory(ca_sn) {
  try {
    var data = {
      ca_sn: ca_sn
    }

    // let response = await axios.delete(url, { data })
    let response = await axiosDelete(url, data)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
}

/**
 * 카테고리 수정
 * @param {*} ca_sn
 * @param {*} title
 * @param {*} description
 * @param {*} menu_order
 * @param {*} is_hidden
 * @returns
 */
export async function editCategory(
  ca_sn,
  title,
  description,
  menu_order,
  is_hidden,
  is_close,
  lc_no
) {
  try {
    var data = {
      ca_sn: ca_sn,
      title: title,
      contents: description,
      menu_order: menu_order,
      is_hidden: is_hidden,
      is_close,
      lc_no
    }
    console.log(data)
    // let response = await axios.put(url, data)
    let response = await axiosPut(url, data)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
  return null
}

/**
 * 강좌 순서 일괄 업데이트
 * @param {*} [
    {"ca_sn":199, "menu_order":1},
    {"ca_sn":3, "menu_order":1}
] 
 */
export async function updateOrderSN(array) {
  try {
    var URL = url + '/order'
    // var data = JSON.stringify(array)
    let response = await axiosPost(URL, array)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
}
/**
 * 강좌 id 중복 체크
 * @param {*}} lc_no
 * @returns
 */
export async function checkLectureNum(lc_no) {
  try {
    var data = {
      lc_no: lc_no
    }
    var url = 'lecture/dup'
    let response = await axiosPost(url, data)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
  return null
}

/**
 * 강좌 등록/수정
 * @param {*} lc_no
 * @param {*} ca_sn
 * @param {*} file_path
 * @param {*} thumb_url
 * @param {*} content
 * @param {*} play_second
 * @param {*} title
 * @param {*} tags
 * @param {*} is_cost
 * @param {*} lc_sn
 * @param {*} is_close 노출여부(Y/N)
 * @param {*} file_name /202111/9ab14aaa-9009-4533-bdbc-b669b3e0f269.mp4
 * @param {*} thumb_key images/202111/eb48f2a6-b610-4134-8b5f-b74681d3eb91.png
 * @returns
 */
export async function upLoadLecture(
  lc_no,
  ca_sn,
  file_path,
  thumb_url,
  content,
  play_second,
  title,
  tags,
  is_cost,
  lc_sn,
  is_close,
  file_name,
  thumb_key,
  play_order,
  lecturer_name
) {
  try {
    // var url = `/adm_api/lecture`
    var url = `lecture`
    let resp = await axiosPostToUrl(url, {
      lc_no,
      ca_sn,
      file_path,
      thumb_url,
      content,
      play_second,
      title,
      tags,
      is_cost,
      lc_sn,
      is_close,
      file_name,
      thumb_key,
      play_order,
      lecturer_name
    })
    return resp
  } catch (error) {
    console.error(error)
  }
  return null
}
export async function deleteLecture(lc_no) {
  var url = 'lecture'
  const resp = await axiosDelete(url, { lc_no })
  return resp
}
/**
 * 강좌 동영상 업로드
 * @param {*} frmData
 * @returns
 */
export async function upLoadVideo(frmData) {
  try {
    // var url = `lecture/upload`

    const rtToken = store.getters['user/getRefreshToken']
    frmData.append('refreshToken', rtToken)
    console.log(rtToken)
    var url = `comm/upload/vod`
    let response = await axiosUpload(url, frmData, null)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
  return null
}
/**
 * 강좌 썸네일 이미지 업로드
 * @param {*} frmData
 * @returns
 */
export async function uploadImage(frmData) {
  try {
    // var url =`lecture/image/upload`
    var url = `comm/upload/image`
    let resp = await axiosUpload(url, frmData, null)
    return resp
  } catch (error) {
    console.error(`upload image fail, ${error}`)
  }
}
export async function getLectureList(params) {
  try {
    // http://{{adm}}/adm_api/lecture?cate1=&cate2&cate3&searchText
    var url = `lecture?${params}`
    let response = await axiosGet(url)
    return response
  } catch (error) {
    console.log(JSON.stringify(error))
  }
  return null
}

/**
 * 해당강좌 상세정보
 * @param {*} lc_no 
 * @returns {
         "play_order": "6",
        "file_path": "https://vod.fmschool.co.kr:8443/vod/mp4:mp4/202104/6f3ccf6f-dd90-4c8e-a952-e92d012e5674.mp4/playlist.m3u8",
        "thumb_url": "https://fmschool.s3.ap-northeast-2.amazonaws.com/images/202106/57060c5c-5b41-4ef6-986a-220549dbb9c9.jpg",
        "cate1": 4,
        "reg_dt": "2021-04-21T03:57:15.000+00:00",
        "cate2": 15,
        "cate3": 17,
        "is_cost": "Y",
        "lc_no": "A000000001",
        "tag": "1,2",
        "title": "111",
        "content": "<p>강좌 설명을 입력하세요</p>"
      }
 */
export const getLectureInfo = async lc_no => {
  const url = `lecture/${lc_no}`
  try {
    const refreshToken = store.getters['user/getRefreshToken']
    var data = {
      refreshToken: refreshToken
    }
    let response = await axiosPost(url, data)
    return response
  } catch (error) {
    console.error('getLectureInfo - ', error)
  }
}
/**
 * 강좌 순서 변경
 * @param {*} lc_no
 * @param {*} play_order
 * @returns
 */
export async function updateLectureOrder(lc_no, play_order) {
  try {
    var url = 'lecture/order'
    let response = await axiosPut(url, { lc_no, play_order })
    return response
  } catch (e) {
    console.log(e)
  }
  return null
}

/**
 * vkey 얻기
 * @returns vkey
 */
export async function getVkey() {
  const refreshToken = store.getters['user/getRefreshToken']
  let response = await axiosPost('comm/getvkey', { refreshToken })
  return response
}
